<template>
</template>

<script>
export default {
	created() {
        this.$router.replace('/nft');
	},
};
</script>
<style>

</style>
